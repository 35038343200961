function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
import { PullToRefresh } from "pull2";
import { useEffect } from "react";
export function usePullToRefresh(_param, dependencies) {
    var scrollView, options;
    void 0 === dependencies && (dependencies = []), ({ scrollView } = _param), options = function(source, excluded) {
        var target, sourceKeys, key, i;
        if (null == source) return {};
        for(i = 0, target = {}, sourceKeys = Object.keys(source); i < sourceKeys.length; i++)excluded.indexOf(key = sourceKeys[i]) >= 0 || (target[key] = source[key]);
        return target;
    }(_param, [
        "scrollView"
    ]), useEffect(()=>{
        let ins = new PullToRefresh(_extends({}, options, {
            scrollView: scrollView.current
        }));
        return ()=>ins.destroy();
    }, dependencies);
}
